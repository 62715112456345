<template>
  <div ref="formContainer">
    <v-flex xs10 offset-xs1>
      <templates/>
    </v-flex>
  </div>
</template>

<script>
import Templates from '@/components/templates/Templates'

export default {
  components:{
    Templates
  }
}
</script>