var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_c('span',{staticClass:"title font-weight-bold"},[_vm._v(_vm._s(_vm.pTemplate.name))]),_c('v-spacer')],1),_c('v-divider',{attrs:{"light":""}}),_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.pTemplate.srcThumbnail},on:{"click":function($event){return _vm.goTo('template', _vm.pTemplate)}}}),_c('v-card-text',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.pTemplate.description))]),_c('v-divider',{attrs:{"light":""}}),_c('v-card-actions',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [((_vm.pTemplate.default && _vm.$can('manage', 'template') || !_vm.pTemplate.default && _vm.$can('update', 'template')))?_c('v-switch',_vm._g({attrs:{"color":"primary"},on:{"change":function($event){return _vm.changePublished(_vm.pTemplate)}},model:{value:(_vm.pTemplate.published),callback:function ($$v) {_vm.$set(_vm.pTemplate, "published", $$v)},expression:"pTemplate.published"}},on)):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("views.templates.templatelist.buttons.publishUnpublish")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.pTemplate.default)?_c('v-icon',_vm._g({attrs:{"left":"","color":"primary"}},on),[_vm._v(_vm._s(_vm.mdiAttachmentPath))]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("views.templates.templatelist.buttons.default")))])]),_c('v-layout',{attrs:{"align-center":"","justify-end":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$can('create', 'template'))?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.duplicate(_vm.pTemplate)}}},on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(_vm._s(_vm.mdiContentCopyPath))])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("views.templates.templatelist.buttons.duplicate")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [((_vm.$can('update', 'template') && !_vm.pTemplate.default || _vm.$can('manage', 'template')))?_c('v-btn',_vm._g({attrs:{"icon":"","to":{ name: 'editTemplate', params: _vm.pTemplate }}},on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v(_vm._s(_vm.mdiPencilPath))])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("views.templates.templatelist.buttons.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [((_vm.$can('delete', 'template')  && !_vm.pTemplate.default || _vm.$can('manage', 'template')))?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.destroy(_vm.pTemplate)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(_vm._s(_vm.mdiDeletePath))])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("views.templates.templatelist.buttons.delete")))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }