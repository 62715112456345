<template>
  <div>
    <v-card-title>
      <span class="title font-weight-bold">{{ pTemplate.name }}</span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-divider light></v-divider>
    <v-img :src="pTemplate.srcThumbnail" @click="goTo('template', pTemplate)" style="cursor: pointer"></v-img>
    <v-card-text class="font-weight-light">{{pTemplate.description}}</v-card-text>
    <v-divider light></v-divider>
    <v-card-actions>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-switch v-if="(pTemplate.default && $can('manage', 'template') || !pTemplate.default && $can('update', 'template'))"
            color="primary"
            v-model="pTemplate.published"
            @change="changePublished(pTemplate)"
            v-on="on"
            ></v-switch>
          </template>
           <span>{{$t("views.templates.templatelist.buttons.publishUnpublish")}}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon  left color="primary" v-if="pTemplate.default" v-on="on">{{mdiAttachmentPath}}</v-icon>
          </template>
          <span>{{$t("views.templates.templatelist.buttons.default")}}</span>
        </v-tooltip>
        <v-layout align-center justify-end>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="duplicate(pTemplate)" v-if="$can('create', 'template')" v-on="on">
                <v-icon  color="grey">{{mdiContentCopyPath}}</v-icon>
              </v-btn>
            </template>
            <span>{{$t("views.templates.templatelist.buttons.duplicate")}}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :to="{ name: 'editTemplate', params: pTemplate }"
                v-if="($can('update', 'template') && !pTemplate.default || $can('manage', 'template'))"
                v-on="on"
              >
                <v-icon  color="accent">{{mdiPencilPath}}</v-icon>
              </v-btn>
            </template>
            <span>{{$t("views.templates.templatelist.buttons.edit")}}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                @click="destroy(pTemplate)"
                v-if="($can('delete', 'template')  && !pTemplate.default || $can('manage', 'template'))"
                v-on="on"
              >
                <v-icon  color="error">{{mdiDeletePath}}</v-icon>
              </v-btn>
            </template>
            <span>{{$t("views.templates.templatelist.buttons.delete")}}</span>
          </v-tooltip>
        </v-layout>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
import { mdiContentCopy, mdiPencil, mdiDelete, mdiAttachment } from '@mdi/js';

export default {
  name: "template-item",
  props: {
    pTemplate: Object
  },
  data: () => ({
      template: null,
      mdiContentCopyPath: mdiContentCopy,
      mdiPencilPath: mdiPencil,
      mdiDeletePath: mdiDelete,
      mdiAttachmentPath: mdiAttachment
  }),
  methods: {
    ...mapActions("templates", {
      getTemplates: "find",
      deleteTemplate: "destroy",
      getTemplate: "findById",
      save: "save",
      clone: "clone"
    }),
    ...mapActions("notifications", ["error", "success"]),
    async duplicate(pTemplate) {
      try {
        let accepted = await this.$confirm(
          this.$t("views.templates.templatelist.duplicate.confirmDuplicate", { name: pTemplate.name }),
          this.$t("views.templates.templatelist.duplicate.title"), { color: "info", yesLabel: this.$t("views.templates.templatelist.buttons.duplicate") });
        if (accepted) {
          let clonedTemplate = await this.clone(pTemplate.id);
          if(clonedTemplate){
            this.cloneInArray(pTemplate, clonedTemplate);
          }         
        } 
      }catch (error) {
        this.error(error.message);
      }
    },
    async changePublished(pTemplate) {
      try {    
        this.template = pTemplate;
        let msgConfirm = !pTemplate.published ? this.$t("views.templates.templatelist.unpublish.confirmUnpublish", { name: pTemplate.name }) : this.$t("views.templates.templatelist.publish.confirmPublish", { name: pTemplate.name });
        let titleConfirm = !pTemplate.published ? this.$t("views.templates.templatelist.unpublish.title") : this.$t("views.templates.templatelist.publish.title");
        let btnConfirm = !pTemplate.published ? this.$t("views.templates.templatelist.buttons.unpublish") : this.$t("views.templates.templatelist.buttons.publish");
        
        let accepted = await this.$confirm(msgConfirm, titleConfirm, { color: "info", yesLabel: btnConfirm });

        if (accepted){
          let template = await this.getTemplate(pTemplate.id);
          let msgSuccess = template.published ? this.$t("views.templates.templatelist.unpublish.unpublished") : this.$t("views.templates.templatelist.publish.published");
          let dupTemplate = _.clone(template);
          dupTemplate.published = !dupTemplate.published;
          dupTemplate.srcThumbnail = null;
          this.save(dupTemplate);
          this.success(msgSuccess);
        } else {
          this.template.published = !this.template.published;
        }
      } catch (error) {
        this.error(error.message);
      }
    },
    async destroy(template) {
      try {
        let accepted = await this.$confirm(
            this.$t("views.templates.templatelist.delete.confirmDestroy", {name: template.name}),
            this.$t("views.templates.templatelist.delete.title"),{ color: "error", yesLabel: this.$t("views.templates.templatelist.buttons.delete")});
        if (accepted){
          let deleted = this.deleteTemplate(template);
          if (deleted){
            this.remove(template);
          }
        }
      } catch (error) {
        this.error(error.message);
      }
    },
    remove(template) {
      this.$emit("remove", template);
    },
    cloneInArray(originTemplate, clonedTemplate) {
      this.$emit("clone", originTemplate, clonedTemplate);
    },
    goTo(pName, pParams){
      this.$router.push({ name: pName, params: pParams});
    }
  }
};
</script>