<template>
  <div>
    <v-container>
      <v-text-field
        solo-inverted
        hide-details
        :label="this.$t('views.templates.searchTextLabel')"
        :prepend-inner-icon="mdiMagnifyPath"
        color="white"
        v-model="searchText"
        background-color="secondary"
        class="mb-12"
      />
      <v-row>
        <v-col v-for="template in filteredTemplates" :key="template.id" cols="12" xs="12" md="6" lg="3">
          <v-card>
            <template-item
              :pTemplate="template"
              @clone="clone"
              @remove="remove"
            ></template-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mdiMagnify } from '@mdi/js';
import TemplateItem from "@/components/templates/TemplateItem";

export default {
  name: "templates",
   data: () => ({
     templates: null,
     searchText: '',
     mdiMagnifyPath: mdiMagnify
   }),
  components: {
    TemplateItem
  },
  methods: {
    ...mapActions("templates", {
      getTemplates: "find",
      clone: "clone"
    }),
    ...mapActions("notifications", ["error", "success"]),
    clone(originTemplate, clonedTemplate) {
      if (!originTemplate.default){
        this.templates.unshift(clonedTemplate);
      }
      this.success(this.$t("views.templates.templatelist.duplicated"));
    },
    remove(template) {
      const index = this.templates.indexOf(template);
      this.templates.splice(index, 1);
      this.success(this.$t("views.templates.templatelist.deleted"));
    },
  },
  computed: {
    filteredTemplates() {
      const search = this.searchText.toLowerCase().trim();

      if (!search) return this.templates;

      return this.templates.filter(c => c.name.toLowerCase().indexOf(search) > -1);
    }
  },
  async created() {
    let query = '';

    if (this.$route.query) {
      if (this.$route.query.default == undefined) {
        this.$store.dispatch("setTitle", this.$t("views.templates.name"));
      } else if (this.$route.query.default) {
        query = `default=${this.$route.query.default}`;
        this.$store.dispatch("setTitle", this.$t("views.templates.nameParam", { level: this.$t("common.default")}));
      } else {
        query = `default=false`;
        this.$store.dispatch("setTitle", this.$t("views.templates.nameParam", { level: this.$t("common.custom")}));
      }
    }

    try {
      this.templates = await this.getTemplates(query);
    } catch (error) {
      this.error(error.message)
    }
  }
}
</script>